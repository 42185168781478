import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';

const TimelineItem = ({ title, description, expanded, onChange, isLast, isDarkMode }) => {
  return (
    <div className="flex mb-4 w-full">
      {/* <div className="relative w-10">
        <div className={`absolute w-3 h-3 rounded-full top-0 left-1/2 transform -translate-x-1/2 ${isDarkMode ? 'bg-white' : 'bg-black'}`} />
        {!isLast && (
          <div className={`h-full border-l-2  absolute left-1/2 top-3 ${expanded ? 'top-5' : 'top-0'} ${isDarkMode ? 'bg-white' : 'border-black'}`} />
        )}
      </div> */}
      <div className={`flex-1  ${expanded ? '' : ''} ${isDarkMode ? 'border-white' : 'border-black'}`}>
        <div
          className="flex justify-between items-center cursor-pointer p-4"
          onClick={onChange}
        >
          <div>
            <h5 className="text-xl font-semibold">{title}</h5>
          </div>
          <div className="text-gray-500">
            {expanded ? (
              <MinusIcon className='text-black size-8' />
            ) : (
              <PlusIcon className='text-black size-8' />
            )}
          </div>
        </div>
        <div className={`overflow-hidden transition-all duration-500 ease-in-out ${expanded ? 'xl:max-h-[300px] max-md:max-h-[450px] pb-4' : 'max-h-0 pb-0'}`}>
          <p className={`${expanded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300 ease-in-out px-4 pb-3`}>{description}</p>
        </div>
      <hr />
      </div>
    </div>
  );
};

const Timeline = ({ isDarkMode }) => {
  const events = [
    { title: 'Siapa yang dapat menggunakan EduNEX?', description: 'EduNEX dirancang untuk lembaga pendidikan seperti sekolah, universitas, serta organisasi pelatihan untuk meningkatkan efisiensi dan aksesibilitas..' },
    { title: 'Apakah EduNEX tersedia di seluruh Indonesia?', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' },
    { title: 'Bagaimana cara mendaftar ke platform EduNEX', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' },
    { title: 'Apakah EduNEX menyediakan pelatihan untuk pengguna baru?', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { title: 'Apakah ada biaya langganan untuk menggunakan EduNEX', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => () => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <div className="max-w-4xl flex flex-col justify-end p-4 w-full">
      {events.map((event, index) => (
        <TimelineItem
          key={index}
          title={event.title}
          description={event.description}
          expanded={expandedIndex === index}
          onChange={handleAccordionChange(index)}
          isLast={index === events.length - 1}
          isDarkMode={isDarkMode}
        />
      ))}
    </div>
  );
};

export default Timeline;
