import React from 'react'

const getColor = (color) => {
    switch (color) {
        case 'primary':
            return 'bg-blue-500 hover:bg-blue-600';
        case 'success':
            return 'bg-green-500 hover:bg-green-600';
        case 'warning':
            return 'bg-orange-500 hover:bg-orange-600';
    }
}

export default function Button({ color, textColor, children, onClick }) {
    return (
        <button className={`w-auto h-auto px-6 py-2 rounded-lg ${textColor} ${getColor(color)}`
        } onClick={onClick}>
            {children}
        </button>
    )
}
