import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid'
import React from 'react'

export const Card = ({
    imageCard,
    titleCard,
    subtitleCard,
    onClick,
}) => {
  return (
    <div className='w-[400px] h-[424px] flex flex-col gap-6 bg-white rounded-lg p-6 '>
        <div className="w-full flex items-center justify-center">
            <img src={imageCard} alt="" className='w-[120px] h-[120px]' />
        </div>
        <div className="w-full text-center">
            <h1 className='font-bold text-[20px]'>{titleCard}</h1>
            <p className=' text-base'>{subtitleCard}</p>
        </div>
        <div className="w-full flex items-center justify-center">
            <button className='py-[16px] px-[32px] w-fit flex gap-3 border border-blue-600 rounded-lg text-blue-500' onClick={onClick}>
                <p className='font-semibold text-base'>Selengkapnya</p>
                <ArrowLongRightIcon className='text-blue-600 size-6'/>
            </button>
        </div>
    </div>
  )
}
