import React, { useState, useEffect } from "react";
import Logo2 from "../../assets/white_logo.svg";
import mitra1 from "../../assets/logoMitra/mitra_1.svg";
import mitra2 from "../../assets/logoMitra/mitra_2.svg";
import mitra3 from "../../assets/logoMitra/mitra_3.svg";
import mitra4 from "../../assets/logoMitra/mitra_4.svg";
import mitra5 from "../../assets/logoMitra/mitra_5.svg";
import mitra6 from "../../assets/logoMitra/mitra_6.svg";
import mitra7 from "../../assets/logoMitra/mitra_7.svg";
import mitra8 from "../../assets/logoMitra/mitra_8.svg";
import card1 from "../../assets/imageCard/imageCard1.svg";
import card2 from "../../assets/imageCard/imageCard2.svg";
import card3 from "../../assets/imageCard/imageCard3.svg";
import card4 from "../../assets/imageCard/imageCard4.svg";
import card5 from "../../assets/imageCard/imageCard5.svg";
import footerLogo from "../../assets/footerEdunex.svg";
import backtick from "../../assets/backtick.svg";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import youtube from "../../assets/youtube.svg";
import instagram from "../../assets/instagram.svg";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import "./style.css";
import { DarkMode, WbSunny } from "@mui/icons-material";
// import { IconButton } from '@mui/material';
import Navbar from "../../components/organisms/NavigasiBar";
import TimelineComponenet from "../../components/molecules/Timeline";
import Button from "../../components/atoms/Button";
import { Card } from "../../components/molecules/Card";
import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
  StarIcon,
} from "@heroicons/react/24/solid";

const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  
  
  const handleClick = (message) => {
    alert(message);
  }

  const closeMessage = () => {
    setIsMessageVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "beranda",
        "tentang",
        "layanan",
        "testimonial",
        "FAQ",
        "kontak",
      ];
      sections.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          element.classList.toggle(
            "visible",
            rect.top < window.innerHeight && rect.bottom > 0
          );
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  const sectionOffsets = {
    "beranda": 0,
    "tentang": window.innerHeight / 2, // Sesuaikan nilai ini
    "layanan": window.innerHeight / 1, // Sesuaikan nilai ini
    "testimonial": window.innerHeight / 1.5, // Sesuaikan nilai ini
    "FAQ": window.innerHeight / 2, // Sesuaikan nilai ini
    "kontak": window.innerHeight / 2, // Sesuaikan nilai ini
};

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const elementTop =
        element.getBoundingClientRect().top + window.pageYOffset;
        const elementHeight = element.offsetHeight;
        const windowHeight = window.innerHeight;
        const customOffset = sectionOffsets[id] || windowHeight / 2

        const scrollPosition = elementTop - customOffset + (elementHeight / 2);
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  };

  return (
    <div
      className={`Header w-full h-full overflow-x-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="">
      {/* fixed flex gap-4 w-auto px-6 items-center justify-end z-[100] right-0 bottom-6 */}
        {isMessageVisible && (
          <div className="w-[250px] text-xs bg-gray-700 text-white py-2 px-4 rounded-lg flex flex-col items-end justify-center space-x-2">
            <span className="w-fit text-wrap">
              Ini adalah tombol untuk mengganti mode gelap/terang
            </span>
            <button
              onClick={closeMessage}
              className="bg-transparent text-white font-semibold underline"
            >
              <p>tutup</p>
            </button>
          </div>
        )}
        <button
          onClick={toggleDarkMode}
          className="toggle-button flex items-center justify-center w-[50px] h-[50px] border-2 ring-2 ring-blue shadow-md"
        >
          {isDarkMode ? <WbSunny /> : <DarkMode />}
        </button>
      </div>

      {/* HEADER + NAVIGATION */}
      <div className="fixed top-0 left-0 w-full z-[100]">
        <Navbar
          isDarkMode={isDarkMode}
          scrollToSection={scrollToSection}
          bgColor="bg-white"
          showUnderline={true}
        />
      </div>

      {/* HEAD CONTENT */}
      <div className="flex flex-col text-left pt-24">
        <div
          id="beranda"
          className={`flex pt-8 py-12 items-center justify-center w-full h-fit gap-16 px-[100px]`}
        >
          <div className="w-full flex flex-col gap-10">
            <div className={`w-full flex flex-col gap-4`}>
              <h1 className="text-left font-bold text-[40px] space-y-5">
                Transformasi Pendidikan ke Era Digital Kini Lebih Praktis dan
                Terjangkau!
              </h1>
              <p className="text-left text-xl">
                EduNEX hadir sebagai platform penyedia solusi teknologi
                pendidikan yang terintegrasi dan dirancang untuk memudahkan dan
                mendigitalisasi seluruh sistem proses belajar-mengajar.
              </p>
            </div>
            <div className="w-full flex flex-row gap-5">
              <Button color="primary" textColor="text-white" onClick={() => handleClick("Laman belum tersedia!")}>
                Layanan Kami
              </Button>
              <button className="w-auto h-auto px-6 py-2 rounded-lg border border-blue-500 text-blue-500" onClick={() => handleClick("Laman belum tersedia!")}>
                Kontak Kami
              </button>
            </div>
          </div>

          <div>
            {/* {!isMobile && ( */}
            <div
              className="w-[458px] h-[458px] col-span-3 flex items-center justify-center bg-blue-100 rounded-2xl"
              id="bg-image1"
            >
              <img id="image" src={image1} alt="homepage" className="w-fit" />
            </div>
            {/* )} */}

            {/* {isMobile && (
              <div className="w-full h-full flex items-center justify-center col-span-1">
                <img id="image" src={home} alt="homepage" className="w-1/2" />
              </div>
            )} */}
          </div>
        </div>

        {/* mitra_section      */}
        <div className="flex flex-col gap-12 w-full bg-blue-600 text-white text-center justify-center px-[100px] py-[88px]">
          <h1 className="text-xl">
            Kami dipercaya lebih dari 10 lembaga pendidikan untuk membantu
            mereka mentransformasi sistem digital.
          </h1>
          <div className="w-full flex gap-16 items-center justify-between">
            <img src={mitra1} alt="" className="w-24 h-24" />
            <img src={mitra2} alt="" className="w-24 h-24" />
            <img src={mitra3} alt="" className="w-24 h-24" />
            <img src={mitra4} alt="" className="w-24 h-24" />
            <img src={mitra5} alt="" className="w-24 h-24" />
            <img src={mitra6} alt="" className="w-24 h-24" />
            <img src={mitra7} alt="" className="w-24 h-24" />
            <img src={mitra8} alt="" className="w-24 h-24" />
          </div>
        </div>

        <div
          id="tentang"
          className={`flex pt-8 py-12 items-center justify-center w-full gap-16 px-[100px]`}
        >
          <div>
            {/* {!isMobile && ( */}
            <div className="w-[458px] h-[458px] col-span-3 flex items-center justify-center bg-blue-100 rounded-2xl"
            id="bg-image2">
              <img id="image" src={image2} alt="tentang" className="w-fit" />
            </div>
            {/* )} */}

            {/* {isMobile && (
              <div className="w-full h-full flex items-center justify-center col-span-1">
                <img id="image" src={home} alt="homepage" className="w-1/2" />
              </div>
            )} */}
          </div>

          <div className="w-full flex flex-col gap-10">
            <div className={`w-full flex flex-col gap-4`}>
              <h1 className="text-left font-bold text-[40px] space-y-5">
                Tentang EduNEX
              </h1>
              <p className="text-left text-xl">
                EduNEX adalah platform inovasi pendidikan yang mengintegrasikan
                teknologi modern untuk mendukung proses belajar-mengajar. Kami
                membantu lembaga pendidikan bertransformasi menghadapi era
                digital.
              </p>
            </div>

            <div className="w-full h-fit flex gap-16 bg-blue-600 rounded-2xl text-white px-[40px] py-[24px] text-nowrap">
              <div className="gap-16 flex flex-row">
                <div className="gap-1">
                  <h1 className="font-bold text-[28px]">2+</h1>
                  <h1 className="font-semibold text-base">Tahun Pengalaman</h1>
                </div>
                <div className="gap-1">
                  <h1 className="font-bold text-[28px]">10+</h1>
                  <h1 className="font-semibold text-base">
                    Lembaga Pendidikan
                  </h1>
                </div>
                <div className="gap-1">
                  <h1 className="font-bold text-[28px]">5000+</h1>
                  <h1 className="font-semibold text-base">Pengguna Aktif</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* layanan */}
        <div
          id="layanan"
          className="bg-blue-50 w-full flex flex-col items-center justify-center gap-10 py-[100px]"
        >
          <div className="flex w-full flex-col  text-center items-center justify-center gap-4 ">
            <h1 className="w-full font-bold text-[40px]">Layanan Kami</h1>
            <p className="text-xl w-[830px] ">
              Dengan fokus pada efisiensi, kemudahan akses, dan inovasi, kami
              membantu lembaga pendidikan menciptakan ekosistem pembelajaran
              modern.
            </p>
          </div>
          <div className="px-[100px] gap-10 w-full flex flex-col">
            <div className="flex w-full gap-6 items-center justify-center">
              <Card
                imageCard={card1}
                titleCard="CBT (Computer-Based Test)"
                subtitleCard="Sistem ujian berbasis komputer dengan antarmuka intuitif, mendukung pelaksanaan ujian yang akurat, efisien, dan dilengkapi laporan hasil secara otomatis."
                onClick={() => handleClick('Laman belum tersedia!')}
              />
              <Card
                imageCard={card2}
                titleCard="E-Raport"
                subtitleCard="Solusi digital untuk pembuatan raport, memungkinkan guru mengelola nilai siswa secara cepat dan terstruktur, dengan output yang sesuai standar pendidikan."
                onClick={() => handleClick('Laman belum tersedia!')}
              />
              <Card
                imageCard={card3}
                titleCard="E-SPP"
                subtitleCard="Sistem pembayaran SPP berbasis digital yang memudahkan pembayaran spp serta dilengkapi dengan fitur pemberitahuan otomatis melalui WhatsApp."
                onClick={() => handleClick('Laman belum tersedia!')}
              />
            </div>
            <div className="flex gap-6 items-center justify-center w-full">
              <Card
                imageCard={card4}
                titleCard="E-Absensi"
                subtitleCard="Platform pencatatan kehadiran digital yang terintegrasi, memungkinkan pelaporan real-time dan akses mudah oleh guru, siswa, dan orang tua."
                onClick={() => handleClick('Laman belum tersedia!')}
              />

              <Card
                imageCard={card5}
                titleCard="E-SPP"
                subtitleCard="Sistem Penerimaan Peserta Didik Baru berbasis online, dirancang untuk menyederhanakan proses pendaftaran dengan alur kerja yang efisien."
                onClick={() => handleClick('Laman belum tersedia!')}
              />
            </div>
          </div>
        </div>

        {/* testimonial */}

        <div
          id="testimonial"
          className="p-[100px] w-full flex flex-col gap-[60px]"
        >
          <div className="flex w-full flex-col  text-center items-center justify-center gap-4 ">
            <h1 className="w-full font-bold text-[40px]">Testimonial</h1>
            <p className="text-xl w-[830px]">
              Dengan fokus pada efisiensi, kemudahan akses, dan inovasi, kami
              membantu lembaga pendidikan menciptakan ekosistem pembelajaran
              modern.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-12">
            <div className="w-full flex flex-col gap-6 bg-[#F1F7FD] p-10 rounded-2xl">
              <div className="w-full flex justify-between items-center">
                <div className="w-full flex gap-5">
                  <img src={image1} alt="" className="w-16 h-16 rounded-full" />
                  <div className="">
                    <h1 className="text-xl font-bold">Maulana</h1>
                    <p className="">Kepala Sekolah SMKN 4 Banjarbaru</p>
                  </div>
                </div>
                <img src={backtick} className="w-10 h-8" alt="" />
              </div>
              <hr className="border " />
              <div className="">
                <p>
                  Saya sangat terkesan dengan fleksibilitas yang ditawarkan oleh
                  online course ini. Saya bisa belajar sesuai dengan jadwal saya
                  sendiri tanpa harus mengorbankan waktu kerja. Materi yang
                  diajarkan sangat relevan dan instrukturnya sangat
                  berpengalaman.
                </p>
                <div className="flex gap-2">
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col gap-6 bg-[#F1F7FD] p-10 rounded-2xl">
              <div className="w-full flex justify-between items-center">
                <div className="w-full flex gap-5">
                  <img src={image2} alt="" className="w-16 h-16 rounded-full" />
                  <div className="">
                    <h1 className="text-xl font-bold">Christine Aguilera</h1>
                    <p className="">Akademik SMA 1 Bati-Bati</p>
                  </div>
                </div>
                <img src={backtick} className="w-10 h-8" alt="" />
              </div>
              <hr className="border " />
              <div className="">
                <p>
                  Online course ini membantu saya dalam mengembangkan
                  keterampilan teknis. Saya belajar pemrograman dari nol dan
                  sekarang bisa membangun aplikasi sendiri. Materi yang
                  disajikan dengan baik dan ada dukungan forum diskusi yang
                  membantu dalam memecahkan masalah.
                </p>
                <div className="flex gap-2">
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                  <StarIcon className="text-[#FBB837] size-6" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* frequently asked */}
        <div className="w-full flex gap-16 items-center justify-between p-[100px] bg-blue-50"
        id="FAQ">
          <div className="w-[458px] flex flex-col gap-10">
            <h1 className="text-[40px] font-bold">
              Frequently Asked Questions
            </h1>
            <p className="text-xl`">
              Temukan jawaban untuk pertanyaan umum tentang EduNEX dan layanan
              kami. Jika pertanyaan Anda tidak tercantum di sini, jangan ragu
              untuk menghubungi kami.
            </p>
          </div>

          <div className="w-full h-auto flex items-center justify-end">
            <TimelineComponenet />
          </div>
        </div>

        <div className="p-16"
        id="kontak">
          <div className="w-full flex gap-16 p-[100px] bg-blue-600 rounded-2xl">
            <div className="w-[450px]">
              <img src={Logo2} alt="" className="w-[144px]" />
              <div className="text-start text-white">
                <h1 className="text-[40px] font-bold">Kontak Kami</h1>
                <p className="text-xl">
                  Apakah Anda memiliki pertanyaan, membutuhkan informasi lebih
                  lanjut, atau ingin bermitra dengan kami? Tim EduNEX siap
                  membantu Anda.
                </p>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full flex flex-col gap-6">
                <div className="w-full flex items-center justify-start bg-blue-900 p-6 rounded-lg">
                  <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center">
                    <EnvelopeIcon className="text-blue-900 size-6" />
                  </div>
                  <div className="pl-6 text-white">
                    <h1 className="text-xl font-bold">Email</h1>
                    <p className="text-base">misterx@edunex.id</p>
                  </div>
                </div>

                <div className="w-full flex items-center justify-start bg-blue-900 p-6 rounded-lg">
                  <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center">
                    <PhoneIcon className="text-blue-900 size-6" />
                  </div>
                  <div className="pl-6 text-white">
                    <h1 className="text-xl font-bold">Telepon/Whatsapp</h1>
                    <p className="text-base">+62 822-6576-8589</p>
                  </div>
                </div>

                <div className="w-full flex items-center justify-start bg-blue-900 p-6 rounded-lg">
                  <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center">
                    <MapPinIcon className="text-blue-900 size-6" />
                  </div>
                  <div className="pl-6 text-white">
                    <h1 className="text-xl font-bold">Alamat</h1>
                    <p className="text-base">
                      EdunexSpace, Jl. Unknown No. xx, Banjarmasin, Kalimantan
                      Selatan, 74512
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CarouselSlide /> */}
      {/* FOOTER */}
      <div
        className={`bg-[#29467F] w-full h-fit px-16 py-6 gap-16 flex flex-col ${
          isDarkMode ? "bg-slate-800 text-slate-100" : ""
        }`}
      >
        <div className="flex flex-col items-center justify-center w-full text-white gap-10">
          <div className="w-full h-auto py-6 flex items-center justify-center">
            <img src={footerLogo} alt="" />
          </div>
          <div className="">
            <Navbar showLogo={false} showButton={false} showUnderline={false} />
          </div>
          <div className="flex items-center justify-center w-full gap-3 ">
            <img src={instagram} alt="" />
            <img src={youtube} alt="" />
            <img src={twitter} alt="" />
            <img src={facebook} alt="" />
          </div>
        </div>

        <div className="text-white flex flex-col items-center justify-center ">
          <div className="container mx-auto border-b border-gray-300 gap-2"></div>
          <div className="">
            Copyright © 2025 All Right Reserved | Designed by EduNEX
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
