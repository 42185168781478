import React, { useState, useEffect } from 'react';

const InfoPanelLeft = ({ isDarkMode, srcImg, title, id, heading, context }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`w-full h-fit pt-12 py-12 flex flex-col ${isDarkMode ? "bg-slate-800" : "bg-slate-200"} justify-center`}>
      <div className="flex justify-center pt-12">
        <h1 className={`font-bold mb-4 pt-4 opacity-35 ${isSmallScreen ? 'text-6xl' : 'text-8xl'}`}>
          {title}
        </h1>
      </div>
      <div id={id} className="section grid grid-cols-1 md:grid-cols-2 gap-8 px-4 py-12 text-lg items-start justify-center">
        <div className="content flex flex-col">
          <h1 className={`font-semibold pb-6 ${isSmallScreen ? 'text-lg' : 'text-3xl'}`}>
            {heading}
          </h1>
          <p className={`text-lg ${isSmallScreen ? 'text-sm' : ''}`}>
            {context}
          </p>
        </div>
        <div className="w-full flex items-center justify-center">
          <img id="image" src={srcImg} alt="product1" className="w-3/4 md:w-1/2" />
        </div>
      </div>
    </div>
  );
};

export default InfoPanelLeft;
